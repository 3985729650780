@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300&family=Shizuru&display=swap");

.page {
  height: 700px !important;
  border-bottom: 2px solid black !important;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(54, 54, 54);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6c65d9;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6c65d9;
  width: 10px;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea {
  font-family: "Poppins", sans-serif !important;
}

code {
  font-family: "Poppins", sans-serif;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  background-color: transparent !important;
}

/* Default font size for small screens (up to 600px) */
p {
  font-size: 10px !important;
}
h1 {
  font-size: 24px !important;
  font-weight: 500 !important;
}
h2 {
  font-size: 18px !important;
  font-weight: 500 !important;
}
h3 {
  font-size: 16px !important;
  font-weight: 500 !important;
}
h6 {
  font-size: 12px !important;
  font-weight: 500 !important;
}
button {
  font-size: 10px !important;
}
input {
  font-size: 10px !important;
}
textarea {
  font-size: 10px !important;
}
.MuiFormControlLabel-label {
  font-size: 10px !important;
}

/* Font size for screens between 600px and 1200px */
@media screen and (min-width: 600px) and (max-width: 1200px) {
  p {
    font-size: 10px !important;
  }
  h1 {
    font-size: 24px !important;
  }
  h2 {
    font-size: 18px !important;
  }
  h3 {
    font-size: 16px !important;
  }
  h6 {
    font-size: 12px !important;
  }
  button {
    font-size: 10px !important;
  }
  input {
    font-size: 10px !important;
  }
  textarea {
    font-size: 10px !important;
  }
  .MuiFormControlLabel-label {
    font-size: 10px !important;
  }
}

/* Font size for screens larger than 1200px */
@media screen and (min-width: 1200px) {
  p {
    font-size: 10px !important;
  }
  h1 {
    font-size: 24px !important;
  }
  h2 {
    font-size: 18px !important;
  }
  h3 {
    font-size: 16px !important;
  }
  h6 {
    font-size: 12px !important;
  }
  button {
    font-size: 12px !important;
  }
  input {
    font-size: 12px !important;
  }
  textarea {
    font-size: 12px !important;
  }
  .MuiFormControlLabel-label {
    font-size: 12px !important;
  }
}

/* Font size for large screens (you can adjust the min-width as needed) */
@media screen and (min-width: 1537px) {
  p {
    font-size: 14px !important;
  }
  h1 {
    font-size: 36px !important;
  }
  h2 {
    font-size: 24px !important;
  }
  h3 {
    font-size: 22px !important;
  }
  h6 {
    font-size: 14px !important;
  }
  button {
    font-size: 14px !important;
  }
  input {
    font-size: 14px !important;
  }
  textarea {
    font-size: 14px !important;
  }
  .MuiFormControlLabel-label {
    font-size: 14px !important;
  }
}
