/* Scene Headers */
.container p.scene {
  font-weight: bolder;
  text-transform: uppercase;
  margin: 10px 0px;
}

/* Action Blocks */
.container p.action {
  margin-bottom: 10px;
  text-align: initial;
}

/* Character Names */
.container p.character {
  text-align: center;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 0px;
  font-weight: 600;
}

.container p.dialogue {
  margin-bottom: 20px;
  margin-top: 0px;
  word-spacing: 4px;
  padding-left: 19%;
}

.container p.parenthetical {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 37%;
}

/* .container p.synopsis {
    font-style: italic;
    color: red;
    margin: 0 0 1em 0;
}


.container p.synopsis:empty {
    display: none;
} */
